import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { parseAuthorPage } from '../parsers'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import ContentGrid from '../components/ContentGrid'

const tileContainer = (theme) => css`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	column-gap: 12px;
	row-gap: 12px;
	max-width: 745px;

	a {
		color: inherit;
	}

	${theme.breakpoints.tablet} {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	${theme.breakpoints.mobile} {
		grid-template-columns: 1fr 1fr 1fr;
	}
`

const letterStyle = (theme) => css`
	${theme.typography.big};
`

const linkStyle = (theme) => css`
	${theme.typography.basic};
	text-decoration: none;
`

const tileStyle = css`
	.gatsby-image-wrapper {
		height: 115px;
	}
`

const StaticPage = ({ data, pageContext }) => {
	const lang = pageContext.language
	const { authors } = parseAuthorPage(data)
	let slug = '/tworcy'
	let enSlug = '/en/creators'
	let linkToDifferentLanguage = enSlug
	if (lang === 'en') {
		linkToDifferentLanguage = slug
	}
	const groupped = authors.reduce((acc, current) => {
		const letter = current.title.split(' ')[1][0].toUpperCase()
		return {
			...acc,
			[letter]: [...(acc[letter] || []), current],
		}
	}, {})
	const finallyParsed = Object.keys(groupped)
		.sort((itemA, itemB) => {
			if (itemA < itemB) {
				return -1
			}
			if (itemA > itemB) {
				return 1
			}
			return 0
		})
		.reduce((acc, current) => {
			return [
				...acc,
				{
					type: 'letter',
					letter: current,
				},
				...groupped[current].map((item) => ({
					...item,
					type: 'creator',
				})),
			]
		}, [])
	return (
		<Layout menu="1970" language={lang} linkToDifferentLanguage={linkToDifferentLanguage}>
			<SEO title="Autorzy" />
			<ContentGrid>
				<div css={tileContainer}>
					{finallyParsed.map((item, index) => (
						<div css={tileStyle} key={index}>
							{item.type === 'letter' && <div css={letterStyle}>{item.letter}</div>}
							{item.type === 'creator' && (
								<div>
									<Link to={lang === 'en' ? item.enSlug : item.slug} css={linkStyle}>
										{item.image && <Img fluid={item.image.childImageSharp.fluid} alt="" />}
										{item.title}
									</Link>
								</div>
							)}
						</div>
					))}
				</div>
			</ContentGrid>
		</Layout>
	)
}

export default StaticPage

export const query = graphql`
	{
		allMarkdownRemark(
			filter: { frontmatter: { layout: { eq: "creator" } } }
			sort: { fields: [frontmatter___title], order: ASC }
		) {
			edges {
				...CreatorData
			}
		}
	}
`
